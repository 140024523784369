import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

export default function useFeaturesVersions() {
  const { locale } = useIntl();

  const response = useStaticQuery(
    graphql`
      query MyQuery {
        features: allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "features" } } }
          sort: { fields: frontmatter___weight }
        ) {
          nodes {
            frontmatter {
              link_youtube
              link_bilibili
            }
          }
        }
        versions: allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "versions" } } }
          sort: { fields: frontmatter___weight }
        ) {
          nodes {
            frontmatter {
              title
              img
            }
            internal {
              content
            }
            fileAbsolutePath
            html
          }
        }
      }
    `
  );

  const {
    features: { nodes: nodesFeatures },
    versions: { nodes: nodesVersions },
  } = response;

  const filterLang = (list, locale) =>
    list.filter(el => {
      const pathArr = el.fileAbsolutePath.split(".");
      return pathArr[pathArr.length - 2] === locale;
    });

  return {
    features: nodesFeatures,
    versions: filterLang(nodesVersions, locale),
  };
}
