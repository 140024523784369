import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Slider from "@components/Slider";
import { SLIDER_PANEL_WIDTH } from "@models";
import Panel from "@components/Panel";

const VideosSlider = ({ videos }) => {
  const { locale } = useIntl();

  return (
    <Slider>
      {videos.map(({ frontmatter: { link_youtube, link_bilibili } }, idx) => (
        <Panel
          key={idx}
          style={{
            width: SLIDER_PANEL_WIDTH,
            maxWidth: "880px",
            marginTop: "20px",
          }}>
          <iframe
            src={locale === "zh-Hans" ? link_bilibili : link_youtube}
            title="Deemo 2"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Panel>
      ))}
    </Slider>
  );
};

export default VideosSlider;
