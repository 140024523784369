import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Title from "@components/Title";
import Slider from "@components/Slider";
import Panel from "@components/Panel";
import useFeaturesVersions from "@hooks/useFeaturesVersions";
import { SLIDER_PANEL_WIDTH } from "@models";
import "./style.scss";

const VersionSlider = () => {
  const { versions } = useFeaturesVersions();
  const { locale } = useIntl();

  return (
    <section
      className="version-slider"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        padding: "0 0 50px 0",
      }}>
      <div
        style={{
          width: SLIDER_PANEL_WIDTH,
          maxWidth: "880px",
          marginTop: "20px",
        }}>
        <Title page="versions" locale={locale} />
        <Slider>
          {versions.map(({ frontmatter: { title, img }, internal: { content }, html }, idx) => (
            <Panel
              key={idx}
              style={{
                width: SLIDER_PANEL_WIDTH,
                maxWidth: "880px",
                marginTop: "20px",
              }}>
              <img
                src={`${process.env.GATSBY_PREFIX}${img}`}
                alt="panel-cover"
                style={{ width: "100%" }}
              />
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "25px",
                  margin: "15px 0 15px 0",
                  textAlign: "center",
                }}>
                {title}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  dangerouslySetInnerHTML={{ __html: html }}
                  style={{ lineHeight: "26px", width: "max-content" }}
                />
              </div>
            </Panel>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default VersionSlider;
