import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Title from "@components/Title";
import useFeaturesVersions from "@hooks/useFeaturesVersions";
import { SLIDER_PANEL_WIDTH } from "@models";
import VideosSlider from "@components/VideosSlider";

import "./style.scss";

const FeatureSlider = () => {
  const { features } = useFeaturesVersions();
  const { locale } = useIntl();

  return (
    <section
      className="feature-slider"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        padding: "0 0 50px 0",
      }}>
      <div
        style={{
          width: SLIDER_PANEL_WIDTH,
          maxWidth: "880px",
          marginTop: "20px",
        }}>
        <Title page="feature" locale={locale} />
        <VideosSlider videos={features} />
      </div>
    </section>
  );
};

export default FeatureSlider;
