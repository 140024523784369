import React from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import NewsSlider from "@components/NewsSlider";
import FeatureSlider from "@components/FeatureSlider";
import VersionSlider from "@components/VersionSlider";
import BtnDark from "@components/BtnDark";
import sitemap from "@models/sitemap.json";
import { isMobile } from "react-device-detect";

const IndexPage = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <>
      <div id="index-kv">
        {isMobile ? (
          <BtnDark
            component="a"
            href={
              locale === "zh-Hans"
                ? process.env.REACT_APP_Taptap_URL
                : process.env.REACT_APP_APP_ADJUST_URL
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              position: "absolute",
              bottom: "82px",
              left: "50%",
              transform: "translateX(-50%)",
            }}>
            {formatMessage({ id: "index-try-game-form" })}
          </BtnDark>
        ) : (
          <Link to={`/${locale}${sitemap.download.path}`}>
            <BtnDark
              rel="noopener noreferrer"
              style={{
                position: "absolute",
                bottom: "82px",
                left: "50%",
                transform: "translateX(-50%)",
              }}>
              {formatMessage({ id: "index-try-game-form" })}
            </BtnDark>
          </Link>
        )}
      </div>
      <div style={{ position: "relative" }}>
        <NewsSlider page="index" />
        <div
          style={{
            position: "absolute",
            bottom: "-4px",
            height: "60px",
            width: "100%",
            backgroundImage: "linear-gradient(transparent, #1f2025)",
          }}
        />
      </div>
      <section className="version-section">
        <VersionSlider />
      </section>
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}>
        <FeatureSlider />
      </div>
    </>
  );
};

export default IndexPage;
